<template>
  <div  class="about">
   <h1 class="focim1">
      Galéria
      <h1 class="focim2">Graphisoft</h1>
    </h1>
    <a href="/" class="fixtopback">
    &larr; Back</a>
    <b-row align-v="center" align-h="around">
      <galery-picture
        class="munka"
        v-for="munka in munkak"
        :key="munka.id"
        :name="munka.name"
        :imglink="munka.imglink"
        :leiras="munka.leiras"
        :sitelink="munka.sitelink"
        :isgalery="munka.isgalery"
      ></galery-picture>
    </b-row>
  </div>
</template>

<script>
import GaleryPic from '@/components/GaleryPic.vue'

export default {
  name: 'About',
  components: {
    'galery-picture': GaleryPic
  },
  mounted () {
    this.fetchData()
  },
  data () {
    return {
      munkak: []
    }
  },
  methods: {
    async fetchData () {
      const res = await fetch('munkak.json')
      const val = await res.json()
      this.munkak = val
    }
  }
}
</script>

<style>

.about{
  padding-left:2em;
  padding-right:2em;
}

.fixtopback{
  background-color: rgb(190, 190, 190);
  color: #fff;
  position: fixed;
  right:25px;
  top:100px;
  z-index:10;
  padding: 10px;
}
</style>
